import { APP_INITIALIZER, ErrorHandler, Provider, enableProdMode, importProvidersFrom } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { browserTracingIntegration, init, TraceService, createErrorHandler } from '@sentry/angular';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

import { AppConfig } from '@ygm/common/core/services/app.config';
import { ErrorCatchInterceptor } from '@ygm/common/core/interceptors/error-catch.interceptor';
import { AuthInterceptor } from '@ygm/common/core/interceptors/auth.interceptor';
import { APP_MODE } from '@ygm/common/core/utils/app-mode';
import { ExpiredTokenInterceptor } from '@ygm/common/core/interceptors/expired-token.interceptor';

import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { PublicAppConfig } from './app/features/shared/public-app.config';
import { provideWebAppRoutes } from './app/features/shared/web-route-paths';
import { PUBLIC_APP_MODE } from './app/features/shared/app-mode';
import { PublicRequestInterceptor } from './app/core/interceptors/public-request.interceptor';

const LOCAL_URLS = ['localhost:4200', '127.0.0.1:4200'];

const httpInterceptorProviders = [
	// The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ErrorCatchInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: PublicRequestInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ExpiredTokenInterceptor, multi: true },
];

const sentryProviders: Provider[] = [
	{
		provide: ErrorHandler,
		useValue: createErrorHandler({
			showDialog: false,
		}),
	},
	{
		provide: TraceService,
		deps: [Router],
	},
	{
		provide: APP_INITIALIZER,
		useFactory: () => () => undefined,
		deps: [TraceService],
		multi: true,
	},
];

const isProduction = import.meta.env.NG_APP_PRODUCTION.toLowerCase() === 'true';

/** Checks whether the app works in prod environment. */
function isProdEnvironment(): boolean {
	return isProduction;
}

if (isProdEnvironment()) {
	enableProdMode();
}

init({
	dsn: import.meta.env.NG_APP_SENTRY_DSN,
	integrations: [browserTracingIntegration()],
	environment: import.meta.env.NG_APP_ENV,
	enabled: import.meta.env.NG_APP_ENV === 'development',
	tracesSampleRate: 1.0,
	denyUrls: LOCAL_URLS,
});

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule),
		...sentryProviders,
		...httpInterceptorProviders,
		{ provide: AppConfig, useClass: PublicAppConfig },
		{ provide: APP_MODE, useValue: PUBLIC_APP_MODE },
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'MM/dd/yyyy' } },
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideEnvironmentNgxMask(),
		provideRouter(appRoutes),
		provideWebAppRoutes(),
	],
})
	.catch(err => console.error(err));
