import { Injectable } from '@angular/core';

import { AppConfig } from '@ygm/common/core/services/app.config';

/** App-specific implementation of app config. */
@Injectable()
export class PublicAppConfig extends AppConfig {
	/** @inheritdoc */
	public readonly apiUrl: string = import.meta.env.NG_APP_API_URL;
}
