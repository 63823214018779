import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Observable, filter, finalize, ignoreElements, merge, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';
import { ERROR_NOTIFICATION_DURATION_MS, ErrorNotifierComponent } from '@ygm/common/shared/components/error-notifier/error-notifier.component';
import { NotificationService } from '@ygm/common/core/services/notification.service';
import { filterNull } from '@ygm/common/core/utils/rxjs/filter-null';
import { PageNotFoundComponent } from '@ygm/common/shared/components/page-not-found/page-not-found.component';
import { IconsService } from '@ygm/common/core/services/icons.service';
import { DialogsService } from '@ygm/common/core/services/dialogs.service';
import { InformationDialogComponent } from '@ygm/common/shared/components/information-dialog/information-dialog.component';
import { ChamberColorsService } from 'projects/public-web/src/app/core/services/chamber-colors.service';
import { DialogData } from 'projects/admin-web/src/app/features/shared/components/dialog/dialog.component';

import { ICONS } from './features/shared/utils/icons-declaration';
import { FooterComponent } from './layouts/components/footer/footer.component';
import { HeaderComponent } from './layouts/components/header/header.component';

/** Root component. */
@Component({
	selector: 'ygmpw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterModule,
		HeaderComponent,
		FooterComponent,
		PageNotFoundComponent,
		MatSnackBarModule,
		MatDialogModule,
	],
	providers: [Document, DialogsService],
})
export class AppComponent implements OnInit {

	private readonly iconsService = inject(IconsService);

	private readonly notificationService = inject(NotificationService);

	private readonly chamberValidationService = inject(ChamberValidationService);

	private readonly chamberColorsService = inject(ChamberColorsService);

	private readonly snackbar = inject(MatSnackBar);

	private readonly dialogsService = inject(DialogsService);

	private readonly destroyRef = inject(DestroyRef);

	/**
	 * Whether the subdomain is valid or not.
	 * @param chamberId Chamber id.
	 */
	public isSubdomainValid(chamberId: number | null): boolean {
		return chamberId !== null;
	}

	public constructor() {
		ICONS.forEach(icon => this.iconsService.addIcon(icon, 'assets/icons/'));
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		merge(
			this.showErrorNotificationSideEffect(),
			this.setChamberColorsSideEffect(),
			this.showNotificationMessageSideEffect(),
		)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();
	}

	private showErrorNotificationSideEffect(): Observable<void> {
		return this.notificationService.errorMessage$.pipe(
			filterNull(),
			tap(errorMessage => this.snackbar.openFromComponent(ErrorNotifierComponent, {
				data: { error: errorMessage },
				duration: ERROR_NOTIFICATION_DURATION_MS,
				horizontalPosition: 'right',
				verticalPosition: 'top',
				panelClass: 'ygm-snackbar',
			})),
			ignoreElements(),
		);
	}

	private setChamberColorsSideEffect(): Observable<void> {
		return this.chamberValidationService.isSubdomainValid$.pipe(
			filter(Boolean),
			tap(() =>
				this.chamberColorsService.setChamberColorsOptions(this.chamberValidationService.chamberInformation.branding.colors)),
			ignoreElements(),
		);
	}

	private showNotificationMessageSideEffect(): Observable<void> {
		return this.notificationService.notificationMessage$.pipe(
			filterNull(),
			switchMap(notificationData => this.dialogsService.openDialogWith<InformationDialogComponent, DialogData, boolean>(
				InformationDialogComponent,
				{
					title: 'Message',
					message: notificationData.message,
				},
			).pipe(
				finalize(() => {
					if (notificationData.action) {
						notificationData.action();
					}
				}),
			)),
			ignoreElements(),
		);
	}
}
