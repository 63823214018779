import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { filterNull } from '@ygm/common/core/utils/rxjs/filter-null';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';
import { UserService } from '../services/user.service';
import { hasRequiredInfo } from '../../features/shared/utils/check-user-required-info';

/** Guard prevents user from accessing pages if he does not have required business information details. */
export function userWithoutDetailsGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const userService = inject(UserService);

	return userService.currentUser$.pipe(
		filterNull(),
		map(user => !hasRequiredInfo(user) ? router.parseUrl(routePaths.businessInformation.path) : true),
		first(),
	);
}
