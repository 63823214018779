@if (hasErrors) {
	<div class="error-notification">
		<h1 class="error-notification__title typography-headline">Error!</h1>
		<hr class="error-notification__divider" />
		@if (errors.length === 1) {
			<p class="error-notification__message">
				{{ errors[0] }}
			</p>
		} @else {
			<ul>
				@for (errorMessage of errors; track errorMessage) {
					<li class="error-notification__message list-item">
						{{ errorMessage }}
					</li>
				}
			</ul>
		}
	</div>
}
