import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DialogComponent } from 'projects/admin-web/src/app/features/shared/components/dialog/dialog.component';

/** Information dialog component. */
@Component({
	selector: 'ygmc-information-dialog',
	styleUrls: ['./information-dialog.component.css'],
	templateUrl: './information-dialog.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [DialogComponent],
})
export class InformationDialogComponent extends DialogComponent { }
