import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';
import { ContractService } from '../services/contract.service';

/** Guard prevents user from accessing if the user does not have any renewal contracts. */
export function emptyRenewalContractsGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);
	const contractService = inject(ContractService);

	return contractService.hasRenewalContracts().pipe(
		map(hasContracts => hasContracts ? true : router.parseUrl(routePaths.profile.path)),
	);
}
