import { IconDeclaration } from '@ygm/common/core/services/icons.service';

/** List of icons to register for mat-icon.  */
export const ICONS: IconDeclaration[] = [
	{
		title: 'goal',
		url: 'goal.svg',
	},
	{
		title: 'message',
		url: 'message.svg',
	},
	{
		title: 'sales',
		url: 'sales.svg',
	},
	{
		title: 'note',
		url: 'note.svg',
	},
	{
		title: 'filter',
		url: 'filter.svg',
	},
	{
		title: 'trade',
		url: 'trade.svg',
	},
	{
		title: 'twitter',
		url: 'twitter.svg',
	},
	{
		title: 'instagram',
		url: 'instagram.svg',
	},
	{
		title: 'success-mark',
		url: 'success-mark.svg',
	},
	{
		title: 'error',
		url: 'error.svg',
	},
	{
		title: 'waiting',
		url: 'waiting.svg',
	},
	{
		title: 'load-more',
		url: 'load-more.svg',
	},
	{
		title: 'calendar',
		url: 'calendar.svg',
	},
	{
		title: 'purchased-members',
		url: 'purchased-members.svg',
	},
	{
		title: 'days-remaining',
		url: 'days-remaining.svg',
	},
	{
		title: 'membership',
		url: 'membership.svg',
	},
	{
		title: 'earnings',
		url: 'earnings.svg',
	},
	{
		title: 'youtube',
		url: 'youtube.svg',
	},
	{
		title: 'linkedin',
		url: 'linkedin.svg',
	},
];
