import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';
import { UserService } from '../services/user.service';

/** Guard prevents user from accessing if a user is not logged in. */
export function unauthorizedGuard(): Observable<boolean | UrlTree> {

	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const userService = inject(UserService);

	return userService.isAuthorized$.pipe(
		map(isAuthorized => (isAuthorized ? true : router.parseUrl(routePaths.landing.path))),
		first(),
	);
}
