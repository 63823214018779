<ygmaw-dialog>
	<div class="dialog__actions_large">
		<button
			(click)="closeDialogWithResult(true)"
			type="button"
			class="ygm-button ygm-button_large"
		>
			Ok
		</button>
	</div>
</ygmaw-dialog>
