import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { catchHttpErrorResponse } from '@ygm/common/core/utils/rxjs/catch-http-error-response';
import { UserSecretStorageService } from '@ygm/common/core/services/user-secret-storage.service';

/** Catches requests with outdated tokens and attempts to refresh it and then retry the request. */
@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {

	private readonly userSecretStorageService = inject(UserSecretStorageService);

	public constructor(private readonly router: Router) { }

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {

		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.shouldHttpErrorBeIgnored(error)) {
					return throwError(() => error);
				}

				return this.userSecretStorageService.removeSecret().pipe(
					switchMap(() => next.handle(req)),
					finalize(() => this.router.navigate(['auth'])),
				);
			}),
		);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		return error.status !== HttpStatusCode.Unauthorized;
	}
}
