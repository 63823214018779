import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';

import { WINDOW } from '@ygm/common/core/utils/window-token';
import { PREVIEW_PARAM_KEY } from '@ygm/common/core/utils/constants';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';

/**
 * Guard prevents a current user from accessing a route in case the site is in preview mode.
 * @param canAccess Whether user is allowed to access preview mode.
 */
export function previewGuard(canAccess: boolean): CanActivateFn {

	return (route: ActivatedRouteSnapshot) => {
		const routePaths = injectWebAppRoutes();
		const router = inject(Router);

		const window = inject(WINDOW);

		const isPreview = (route).queryParamMap.get(PREVIEW_PARAM_KEY);
		const isInIframe = window.location !== window.parent.location;

		const isPreviewMode = isPreview && isInIframe;

		if (canAccess) {
			return isPreviewMode ? of(router.parseUrl(routePaths.preview.path)) : of(true);
		}

		return !isPreviewMode ? of(router.parseUrl(routePaths.landing.path)) : of(true);
	};
}
