import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';
import { UserService } from '../services/user.service';

/** Guard prevents a current user from accessing a route in case they are authorized. */
export function authorizedGuard(): Observable<boolean | UrlTree> {

	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const userService = inject(UserService);

	return userService.isAuthorized$.pipe(
		map(isAuthorized => (isAuthorized ? router.parseUrl(routePaths.landing.path) : true)),
	);
}
